<script>
export default {
  name: 'FormItem',
}
</script>

<template>
  <div :class="$classes['form-item']">
    <slot />
  </div>
</template>

<style module>
.form-item + .form-item {
  margin-top: 30px;
}
</style>
