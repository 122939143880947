<script>
import { mapGetters, mapActions } from 'vuex'
import SlideUpDown from 'vue-slide-up-down'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import SelectField from '@/components/SelectField'
import ModalCheckoutOrder from '@/components/ModalCheckoutOrder'
import ContentLoading from '@/components/ContentLoading'

export default {
  name: 'OrderCart',

  components: {
    Action,
    Icon,
    SelectField,
    ModalCheckoutOrder,
    SlideUpDown,
    ContentLoading,
  },

  data () {
    return {
      loading: false,
      loadingSearch: false,
      selectedOrderID: null,
      showModalCheckoutOrder: false,
      emptyProduct: {
        id: null,
        name: null,
        products: [],
      },
      isExpanded: true,
      totalPriceLoaded: false,
    }
  },

  computed: {
    ...mapGetters('orders', ['orders', 'getSelectedOrder']),
    ...mapGetters('products', ['getPriceAdd']),

    activeOrders: function () {
      return this.orders.filter(function (o) {
        return o.status === 'Em andamento'
      })
    },

    orderDetailRoute () {
      return {
        name: 'orders.details',
        params: {
          id: this.getSelectedOrder.id,
        },
      }
    },

    percentualProductPrice () {
      return 1 + (this.getPriceAdd / 100)
    },

    totalProductPrice () {
      return (product) => (product.quantity * product.price) * this.percentualProductPrice
    },

    orderTotal () {
      return this.getSelectedOrder ? this.getSelectedOrder.products.reduce((value, item) => {
        return value + (item.quantity * item.price * this.percentualProductPrice)
      }, 0) : 0
    },

    canCheckout () {
      return !!this.orderTotal && this.getSelectedOrder.status === 'Em andamento'
    },

    iconName () {
      return this.isExpanded ? 'caret-down' : 'caret-up'
    },

  },

  watch: {
    $media () {
      this.isExpanded = this.$media.desktop
    },

    getSelectedOrder () {
      if (this.getSelectedOrder.id !== this.selectedOrderID) {
        this.selectedOrderID = this.getSelectedOrder.id
        this.handleSelectedOrder()
      }
    },
  },

  async mounted () {
    this.getPriceAdd && this.getSelectedOrder ? this.totalPriceLoaded = true : this.totalPriceLoaded = false
    if (!this.getPriceAdd) {
      this.checkPriceAdd()
        .then(() => {
          this.totalPriceLoaded = true
        })
    }

    if (this.getSelectedOrder) {
      this.selectedOrderID = this.getSelectedOrder.id
      if (this.getSelectedOrder.status === 'Finalizado') {
        this.setFeedback({ title: 'Este pedido já está finalizado, Cod: ' + this.selectedOrderID, message: '', type: 'error' })
        this.selectedOrderID = this.activeOrders[0].id
        this.handleSelectedOrder()
      }
      if (!this.getSelectedOrder?.products) {
        this.handleSelectedOrder()
      }
    } else {
      this.selectedOrderID = this.activeOrders[0].id
    }
  },

  methods: {
    ...mapActions(['setHeaderBack', 'setFeedback']),
    ...mapActions('orders', ['selectOrder', 'getOrderCart', 'removeSelectOrder']),
    ...mapActions('products', ['searchProducts', 'getProduct', 'selectProduct', 'checkPriceAdd']),

    async handleSelectedOrder () {
      await this.getOrderCart({ id: this.selectedOrderID })
    },

    handleCheckoutOrder () {
      this.showModalCheckoutOrder = true
    },

    openProduct (search) {
      if (search === this.productCode) { return }

      if (!this.$media.desktop) {
        this.toggleCart()
      }
      this.loading = true
      this.getProduct({
        search,
      }).then(() => {
        this.loading = false
        this.productCode = search
        return this.$emit('changeTab', 0)
      })
    },

    toggleCart () {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>

<template>
  <div :class="$classes['order-cart-wrapper']" v-if="getSelectedOrder">
    <div :class="$classes['order-cart']">
      <div :class="$classes['order-cart-header']">
        <h2 v-if="$media.desktop" :class="$classes['order-cart-title']">Pedidos</h2>
        <action v-else :class="$classes['order-cart-header-btn']" @click="toggleCart">
          <h2 :class="$classes['order-cart-title']">Pedidos</h2>
          <icon v-if="!$media.desktop" :name="iconName" :class="$classes['order-cart-header-icon']" />
        </action>
      </div>
      <slide-up-down :active="isExpanded" :class="$classes['order-cart-expandable']">
        <div>
          <div :class="$classes['order-cart-content']">
            <select-field label="Pedido atual" v-model="selectedOrderID" @input="handleSelectedOrder">
              <template v-for="order in activeOrders">
                <option :key="order.id" :value="order.id">{{ order.name }}</option>
              </template>
            </select-field>
            <content-loading v-if="!totalPriceLoaded" :height="'100px'"></content-loading>
            <div :class="$classes['order-cart-products']" v-else>
              <h3 :class="$classes['order-cart-products-title']">Produtos</h3>
              <template v-if="!getSelectedOrder.products">
                <p :class="$classes['order-cart-products-message']">Nenhum produto adicionado ao pedido.</p>
              </template>
              <table v-else :class="$classes['order-cart-products-table']">
                <div :class="$classes['order-cart-body']">
                  <thead>
                    <tr>
                      <th width="95">Código</th>
                      <th width="100">Qtd.</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="( product, index ) in  getSelectedOrder.products ">
                      <tr :key="index">
                        <td>
                          <action
                            v-tooltip="{ content: product.description, delay: { show: 100, hide: 100 }, trigger: 'hover', autoHide: true, hideOnTargetClick: true }"
                            :class="$classes['order-cart-product-btn-info']">
                            <icon :class="$classes['order-cart-product-btn-info-icon']" name="info-circle" />
                          </action>
                          <action :class="$classes['order-cart-products-btn']" @click="openProduct(product.code)">
                            {{ product.code }}
                          </action>
                        </td>
                        <td>{{ product.quantity }}</td>
                        <td>{{ totalProductPrice(product) | currency }}</td>
                      </tr>
                    </template>
                  </tbody>
                </div>
                <tfoot>
                  <tr>
                    <td colspan="2">Total</td>
                    <td><strong>{{ orderTotal | currency }}</strong></td>
                  </tr>
                </tfoot>
                <!--<div :class="$classes['order-cart-foot']">
                </div>-->
              </table>
            </div>
          </div>
          <div :class="$classes['order-cart-actions']">
            <action color="primary" variant="flat" :to="orderDetailRoute" uppercase bold>
              <icon name="eye" btnIcon />
              Ver mais
            </action>
            <!--
                                        <action
                                          color="tertiary"
                                          variant="flat"
                                          uppercase
                                          bold
                                          :disabled="!canCheckout"
                                          @click="handleCheckoutOrder"
                                          <icon name="share" btnIcon />
                                          Finalizar
                                        </action>
                                        -->
          </div>
        </div>
      </slide-up-down>
    </div>

    <modal-checkout-order :active.sync="showModalCheckoutOrder" :order="getSelectedOrder" />
  </div>
</template>

<style module>
.order-cart-wrapper {
  padding: 10px 0;
  position: relative;
}

.order-cart-wrapper:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: url('~@/assets/images/ellipse.png') repeat-x top left;
}

.order-cart-wrapper:after {
  content: '';
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: url('~@/assets/images/ellipse.png') repeat-x bottom left;
}

.order-cart {
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
}

.order-cart-header {
  display: flex;
  align-items: center;
}

.order-cart-title {
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.order-cart-header-btn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}

.order-cart-header-icon {
  color: #a1a1a1;
}

.order-cart-expandable {
  overflow: hidden;
}

.order-cart-content {
  margin-top: 20px;
  margin-bottom: 25px;
  min-height: 200px;
}

.order-cart-products {
  margin-top: 20px;
  display: grid;
}

.order-cart-products-table {
  width: 100%;
}

.order-cart-products-table th {
  font-size: 12px;
  color: #a1a1a1;
  text-transform: uppercase;
  text-align: left;
  height: 25px;
  vertical-align: top;
}

.order-cart-products-table td {
  font-size: 14px;
  color: #292929;
  text-align: left;
  height: 25px;
}

.order-cart-products-table tfoot td {
  text-transform: uppercase;
  height: 25px;
  vertical-align: bottom;
}

.order-cart-products-table tfoot tr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 45px;
  margin-top: 15px;
  column-gap: 88px;
  justify-content: space-between;
}

.order-cart-body {
  overflow: auto;
  width: auto;
  max-height: 40vh;
}

.order-cart-body::-webkit-scrollbar {
  width: 4px;
}

.order-cart-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.order-cart-body::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 5px;
}

.order-cart-body::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.order-cart-products-title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 15px;
}

.order-cart-products-message {
  font-size: 12px;
  color: #A1A1A1;
}

.order-cart-product-btn-info {
  display: inline-block;
  color: #4A94D4;
  margin-right: 5px;
}

.order-cart-product-btn-info-icon {
  width: 16px;
  height: 16px;
}

.order-cart-products-btn {
  display: inline-block;
  font-size: 14px;
  color: #4A94D4;
  text-decoration: underline;
  line-height: 20px;
}

.order-cart-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-height: 899px) {
  .order-cart-body {
    overflow: auto;
    width: auto;
    max-height: 30vh;
  }
}

@media (max-width: 767px) {
  .order-cart-products-table tfoot tr {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 45px;
    margin-top: 15px;
    column-gap: 115px;
  }
}
</style>
