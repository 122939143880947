<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'FormAppendButton',

  components: {
    Action,
    Icon,
  },

  inheritAttrs: false,

  props: {
    iconName: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <action
    variant="flat"
    color="secondary"
    :class="$classes['form-append-btn']"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icon :name="iconName" />
  </action>
</template>

<style module>
.form-append-btn {
  width: 40px;
  height: 40px;
}
</style>
