<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  name: 'Modal',

  components: {
    Action,
    Icon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Boolean,
      default: true,
    },

    width: {
      type: String,
    },

    sideBar: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    loadingStyle () {
      return { width: this.width }
    },
  },

  watch: {
    active () {
      if (this.active) {
        disableBodyScroll(this.$refs.modal, {
          reserveScrollBarGap: true,
        })
      } else {
        enableBodyScroll()
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.checkEscape)
  },

  methods: {
    closeModal () {
      this.$emit('close')
    },

    checkEscape (event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },
  },

  detroyed () {
    window.removeEventListener('keydown', this.checkEscape)
    enableBodyScroll()
  },
}
</script>

<template>
  <div v-if="active && (this.sideBar === 'default')" ref="modal" :class="$classes['modal-blocker']" tabindex="-1">
    <div :class="$classes['modal']" :style="loadingStyle">
      <action v-if="close" color="secondary" variant="flat" type="button" :class="$classes['modal-close']"
        @click="closeModal">
        <icon name="times" />
      </action>
      <slot />
    </div>
  </div>

  <div v-else-if="active && (this.sideBar === 'right')"
    :class="{ [$classes['modal-blocker']]: true, [$classes['sidebar-right']]: true, [$classes['order-animate']]: active }"
    tabindex="-1">
    <div :class="[$classes['modal'], $classes['sidebar-right']]" :style="loadingStyle">
      <action v-if="close" color="secondary" variant="flat" type="button" :class="$classes['modal-close']"
        @click="closeModal">
        <icon name="times" />
      </action>
      <slot />
    </div>
  </div>
</template>

<style module>
.modal-blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 100;
  background-color: rgba(54, 59, 91, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  max-width: 80vh;
  min-width: 50vh;
  height: auto;
  min-height: 276px;
  justify-content: center;
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-blocker.sidebar-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 100;
  background-color: rgba(54, 59, 91, 0.75);
  display: flex;
  align-items: normal;
  justify-content: flex-end;
}

.modal.sidebar-right {
  width: 640px;
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  border-radius: 0px;
  padding: 0px;
  /* justify-content: flex-start; */
}

.order-animate {
  animation-name: animateIn;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  animation-delay: calc(var(--animation-order) * 50ms);
  animation-fill-mode: both;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 1367px) {
  .modal {
    max-width: 70vh;
    width: 90%;
    padding: 40px;
    min-height: 276px;
  }
}

@media (max-width: 767px) {
  .modal {
    max-width: 500px;
    width: 90%;
    padding: 40px;
    min-height: 276px;
  }
}
</style>
