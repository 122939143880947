<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import RadioGroup from '@/components/RadioGroup'
import RadioField from '@/components/RadioField'
import { debounce } from 'lodash'

export default {
  name: 'FilterSearch',

  components: {
    Action,
    Icon,
    RadioGroup,
    RadioField,
  },

  props: {
    filters: {
      type: Array,
      default: () => [],
    },

    defaultValue: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    results: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disable: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      filterSearch: null,
      search: null,
      view: 'block',
      showResults: false,
    }
  },

  watch: {
    $media () {
      if (this.$media.mobile) {
        this.handleView('block')
      }
    },
    results () {
      if (this.results.length > 0) {
        this.showResults = true
      }
    },
  },

  mounted () {
    this.filterSearch = this.defaultValue
    window.addEventListener('keydown', this.checkEscape)
  },

  methods: {
    handleView (viewName) {
      this.view = viewName
      this.$emit('view', this.view)
    },

    handleSearch () {
      this.$emit('search', this.search)
    },

    handleFilter () {
      this.$emit('filter', this.filterSearch)
    },

    selectProduct (product) {
      this.$emit('select', product)
    },

    inputSearch: debounce(function () {
      if (this.search.length >= 3 || this.search.length === 0) {
        this.handleSearch()
      }
    }, 1000),

    checkEscape (event) {
      if (event.keyCode === 27) {
        this.showResults = false
        this.search = ''
      }
    },
  },

  detroyed () {
    window.removeEventListener('keydown', this.checkEscape)
  },

}
</script>

<template>
  <div :class="$classes['filter']">
    <div :class="$classes['filter-type-order']">
      <div :class="$classes['filter-type']">
        <radio-group>
          <template v-for="(filter, index) in filters">
            <radio-field :key="index" is-group :label="filter.label" :value="filter.value" v-model="filterSearch"
              @change="handleFilter" :disabled="disable" />
          </template>
        </radio-group>
      </div>
    </div>
    <form @submit.prevent="handleSearch" :class="$classes['filter-search']">
      <div :class="$classes['filter-search-input-wrapper']">
        <input required="true" oninvalid="this.setCustomValidity('Preencha o campo de pesquisa')"
          oninput="setCustomValidity('')" spellcheck="false" type="text" :placeholder="placeholder" v-model="search"
          @input="inputSearch" :class="$classes['filter-search-input']" :disabled="disable">
        <action type="submit" :class="$classes['search-btn']" :loading="loading" :disabled="disable">
          <icon v-if="!loading" name="search" :class="$classes['search-btn-icon']" />
        </action>
      </div>
      <div :class="$classes['filter-search-results']" v-if="results.length && showResults">
        <ul :class="$classes['filter-search-results-list']">
          <template v-for="(product, index) in results">
            <li :key="index" :class="$classes['filter-search-results-item']">
              <action :class="$classes['filter-search-results-btn']" @click="selectProduct(product)">
                <span :class="$classes['product-code']" style="text-align: left">{{ product.code }}</span>
                <span :class="$classes['product-factory']" style="text-align: left">{{ product.factory }}</span>
                <span :class="$classes['product-description']" style="text-align: left">{{ product.description }}</span>
                <span :class="$classes['product-fullAplication']" style="text-align: left">{{ product.fullApplication }}</span>
              </action>
            </li>
          </template>
        </ul>
      </div>
    </form>
  </div>
</template>

<style module>
.filter-type-order {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-type {
  min-width: 430px;
  margin-right: 30px;
}

.filter-view {
  display: flex;
  align-items: center;
}

.filter-view-action {
  width: 24px;
  height: 24px;
  color: #DBDBDB;
  margin-right: 10px;
}

.filter-view-action.is-active {
  color: #5CC0DE;
}

.filter-view-action-icon {
  width: 16px;
  height: 16px;
}

.filter-search {
  position: relative;
}

.filter-search-input-wrapper {
  display: flex;
}

.filter-search-input {
  border: 0;
  display: block;
  width: 100%;
  flex: 1;
  background: #fff;
  color: #292929;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  padding-left: 30px;
  padding-right: 10px;
}

.search-btn {
  background: #4A94D4;
  color: #FFF;
  width: 50px;
  height: 40px;
  border-radius: 0 2px 2px 0;
}

.search-btn-icon {
  width: 20px;
  height: 20px;
}

.filter-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  border-radius: 2px;
  max-height: 305px;
  margin-top: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 transparent;
}

.filter-search-results::-webkit-scrollbar {
  width: 4px;
}

.filter-search-results::-webkit-scrollbar-track {
  background-color: transparent;
}

.filter-search-results::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 5px;
}

.filter-search-results::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.filter-search-results-item {
  display: block;
  min-height: 60px;
  padding: 5px;
  text-align: left;
}

.filter-search-results-item+.filter-search-results-item {
  border-top: 1px solid #F3F3F4;
}

.filter-search-results-btn {
  min-height: 60px;
  width: 100%;
  padding: 0 0px;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #292929;
  font-size: 14px;
}

.filter-search-results-btn:hover {
  background: rgba(204, 204, 204, 0.175);
}

.product-image-wrapper {
  width: 48px;
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  background: #ccc;
  color: #fff;
  margin-right: 20px;
}

.product-image {
  display: block;
  width: 100%;
  height: 100%;
}

.product-image-icon {
  width: 16px;
  height: auto;
}

.product-code {
  margin-right: 30px;
  width: 100%;
  max-width: 60px;
}

.product-factory, .product-description, .product-fullAplication {
  text-transform: uppercase;
  text-align: left;
  width: 20%;
}

.product-description {
  width: 40%;
}

.product-fullAplication {
  width: 40%;
}

@media (max-width: 767px) {
  .filter-type-order {
    display: block;
  }

  .filter-type {
    min-width: auto;
    margin-right: 0;
  }

  .filter-view {
    margin-top: 20px;
  }

  .filter-search-results-btn {
    padding: 0 10px;
    font-size: 12px;
  }

  .product-image-wrapper {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  .product-code {
    margin-right: 10px;
  }
}
</style>
