<script>
export default {
  name: 'FormAppend',
}
</script>

<template>
  <div
    v-if="$slots.default"
    :class="$classes['form-append']"
  >
    <slot />
  </div>
</template>

<style module>
.form-append {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
</style>
