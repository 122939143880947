<script>
import Icon from '@/components/Icon'

export default {
  name: 'NoResultsMessage',

  components: {
    Icon,
  },

  props: {
    title: {
      type: String,
      default: null,
    },

    message: {
      type: String,
      default: null,
    },

    img: {
      type: String,
      default: 'not-found',
    },

    iconWidth: {
      type: String,
      default: 'auto',
    },
    iconHeight: {
      type: String,
      default: '7rem',
    },
  },
}
</script>

<template>
  <div :class="[$classes['no-result-content']]">
    <span :class="[$classes['icon-wrapper']]">
      <icon
        :name="img"
        :class="[$classes['icon-wrapper'], $classes['not-found']]"
        :style="{ width: iconWidth, height: iconHeight }"
      />
    </span>
    <span :class="[$classes['message'], $classes['not-found']]">
      {{ title }}
    </span>
    <span :class="[$classes['message']]">
      {{ message }}
    </span>
  </div>
</template>

<style module>
.no-result-content {
  margin-top: 2rem;
  padding: 40px;
  text-align: center;
  width: auto;
  /* max-width: 700px; */
  height: auto;
  background-color: white;
  border-radius: 10px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 3px;
}

.icon-wrapper.not-found {
  width: auto;
  height: 7rem;
}

.message {
  display: block;
  text-transform: initial;
  font-size: 14px;
  line-height: 18px;
  color: #292929;
  margin-bottom: 10px;
}

.message.not-found {
  color: #333333;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 10px 20px;
  line-height: 1.5;
}
</style>
