<script>
export default {
  name: 'ModalHeader',

  props: {
    title: {
      type: String,
      default: null,
    },

    message: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <div :class="$classes['modal-header']">
    <h4 :class="$classes['modal-title']">
      {{ title }}
    </h4>
    <p
      v-if="message"
      :class="$classes['modal-message']"
    >
      {{ message }}
    </p>
  </div>
</template>

<style module>
.modal-header {
  margin-bottom: 20px;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(41,41,41,0.8);
}

.modal-message {
  margin-top: 10px;
  font-size: 14px;
  color: #292929;
}
</style>
