<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  name: 'ModalPecas',
  components: {
    Action,
    Icon,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Boolean,
      default: true,
    },
    busca: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Number,
      default: 0,
    },
    alert: {
      type: Boolean,
    },
  },
  data () {
    return {
    }
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['modal-alert']]: this.alert,
      }
    },
  },

  watch: {
    active () {
      if (this.active) {
        disableBodyScroll(this.$refs.modal, {
          reserveScrollBarGap: true,
        })
      } else {
        enableBodyScroll()
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.checkEscape)
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },

    checkEscape (event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },
  },

  detroyed () {
    window.removeEventListener('keydown', this.checkEscape)
    enableBodyScroll()
  },
}
</script>

<template>
  <div v-if="active" ref="modal" :class="$classes['modal-blocker']" tabindex="-1">
    <div role="dialog" :class="[$classes['modal'], computedClasses]">
      <action v-if="close && visible < 3" color="secondary" variant="flat" type="button" :class="$classes['modal-close']"
        @click="closeModal">
        <icon name="times" />
      </action>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" :class="$classes['modal-header']">
            <slot name="header"></slot>

            <div :class="$classes['title']">
              <slot name="title"></slot>
            </div>
          </div>
          <div class="modal-body" :class="$classes['modal-body']">
            <slot name="body"></slot>
          </div>
          <div :class="$classes['modal-footer']">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style module>
.modal-blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 100;
  /* background-color: rgba(0, 0, 0, 0.25); */
  background-color: rgba(54, 59, 91, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: block;
  flex-direction: column;
  position: fixed;
  width: 80%;
  height: auto;
  background: #f3f3f4;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.5s ease; /* Duração e função de interpolação da transição */
}

.modal.modal-alert {
  width: 500px;
  min-height: 276px;
  padding: 40px 30px 30px 30px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (max-width: 767px) {
  .modal {
    max-width: 500px;
    width: 90%;
    padding: 20px;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header,
.modal-footer {
  display: block;
  height: auto;
}

.modal-header {
  /* border-bottom: 1px solid #eeeeee; */
  color: #4aae9b;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  margin-top: 40px;
}

.modal-body {
  padding: 2px 10px 10px 10px;
}

.product-info-label {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: #a1a1a1;
  margin-bottom: 10px;
}

.header-title {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #292929;
  margin-bottom: 20px;
  padding: 0px 30px 0px 0px;
}
</style>
