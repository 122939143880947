<script>
export default {
  name: 'FormInputWrapper',

  inheritAttrs: false,

  props: {
    focus: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['has-focus']]: this.focus,
        [this.$classes['has-error']]: this.error,
        [this.$classes['is-disabled']]: this.disabled,
      }
    },
  },
}
</script>

<template>
  <div
    :class="[$classes['form-input-wrapper'], computedClasses]"
  >
    <slot />
  </div>
</template>

<style module>
.form-input-wrapper {
  display: flex;
  border-bottom: 1px solid #A2A2A2;
  position: relative;
}

.has-error {
  border-color: #D14040;
}

.is-disabled {
  border-bottom-color: rgba(195,198,216,.3);
}
</style>
