<script>
import { mask } from 'vue-the-mask'
import FormItem from '@/components/FormItem'
import FormInputWrapper from '@/components/FormInputWrapper'
import FormLabel from '@/components/FormLabel'
import FormAppend from '@/components/FormAppend'
import FormLabelMessage from '@/components/FormLabelMessage'
import FormAppendButton from '@/components/FormAppendButton'

export default {
  name: 'InputField',

  components: {
    FormItem,
    FormInputWrapper,
    FormLabel,
    FormAppend,
    FormLabelMessage,
    FormAppendButton,
  },

  directives: {
    mask,
  },

  props: {
    autocomplete: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    labelUppercase: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    validation: {
      type: Object,
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },

    mask: {
      type: [String, Array],
      default: null,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isFocused: false,
      passwordVisible: false,
      internalType: this.type,
    }
  },

  computed: {
    directive () {
      if (this.mask) {
        return 'mask'
      }
      return 'default'
    },

    isPassword () {
      return this.type === 'password'
    },

    visibilityIcon () {
      return this.passwordVisible ? 'eye' : 'eye-slash'
    },

    autocapitalize () {
      return this.isPassword ? 'none' : null
    },

    hasError () {
      return (this.validation && this.validation.$error) || false
    },

    computedClasses () {
      return {
        [[this.$classes['form-input']]]: true,
        [[this.$classes['is-disabled']]]: this.disabled,
      }
    },

    inputName () {
      return `input-${this._uid}`
    },
  },

  methods: {
    updateValue (value) {
      this.validation && this.validation.$touch()
      this.$emit('input', value)
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur (event) {
      this.isFocused = false
      this.validation && this.validation.$touch()
    },

    changeVisiblity () {
      this.passwordVisible = !this.passwordVisible
      this.internalType = this.passwordVisible ? 'text' : 'password'
      this.$refs.input.focus()
      setTimeout(() => {
        if (this.$refs.input.setSelectionRange) {
          const valueLength = this.value ? this.value.length * 2 : 0
          this.$refs.input.setSelectionRange(valueLength, valueLength)
        }
      }, 0)
    },
  },
}
</script>

<template>
  <form-item>
    <form-label
      v-if="label"
      :text="label"
      :uppercase="labelUppercase"
      :input-name="inputName"
      :error="hasError"
      :disabled="disabled"
      :validation="validation"
    >
      <form-label-message
        :validation="validation"
      />
    </form-label>
    <form-input-wrapper
      :focus="isFocused"
      :error="hasError"
      :disabled="disabled"
    >
      <input
        v-if="directive === 'mask'"
        :id="inputName"
        ref="input"
        v-mask="mask"
        spellcheck="false"
        :class="computedClasses"
        :autofocus="autofocus"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
      >
      <input
        v-if="directive === 'default'"
        :id="inputName"
        ref="input"
        spellcheck="false"
        :class="computedClasses"
        :autofocus="autofocus"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        :tabindex="999"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
      >
      <form-append>
        <form-append-button
          v-if="isPassword"
          :icon-name="visibilityIcon"
          :disabled="disabled"
          @click="changeVisiblity"
        />
      </form-append>
    </form-input-wrapper>
  </form-item>
</template>

<style module>
.form-input {
  border: 0;
  display: block;
  width: 100%;
  height: 35px;
  flex: 1;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #292929;
  background: none;
}

.is-disabled {
  cursor: text;
}
</style>
