<script>
export default {
  name: 'FormLabel',

  props: {
    text: {
      type: String,
      default: null,
    },

    uppercase: {
      type: Boolean,
      default: false,
    },

    inputName: {
      type: String,
      default: null,
    },

    error: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    validation: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['has-error']]: this.error,
        [this.$classes['is-disabled']]: this.disabled,
        [this.$classes['is-uppercase']]: this.uppercase,
      }
    },

    isRequired () {
      if (this.validation) {
        return this.validation.required !== undefined
      }
      return false
    },
  },

  methods: {
    handleClick () {
      this.$emit('click')
    },
  },
}
</script>

<template>
  <div class="form-label-wrapper">
    <label
      :class="[$classes['form-label'], computedClasses]"
      :for="inputName"
      @click="handleClick"
    >
      {{ text }}
      <template v-if="isRequired">
        *
      </template>
    </label>
    <slot />
  </div>
</template>

<style module>
.form-label-wrapper {
  display: flex;
  align-items: center;
}

.form-label {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  line-height: 15px;
  color: rgba(41, 41, 41, 0.8);
  letter-spacing: 0.1em;
}

.has-error {
  color: #D14040;
}

.is-disabled {
  cursor: default;
}

.is-uppercase {
  text-transform: uppercase;
}
</style>
