<script>
import FormItem from '@/components/FormItem'

export default {
  name: 'RadioGroup',

  components: {
    FormItem,
  },
}
</script>

<template>
  <form-item>
    <div :class="$classes['form-radio-group']">
      <slot />
    </div>
  </form-item>
</template>

<style module>
.form-radio-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
}
</style>
