<script>
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import logger from '@/logger/index'
import ContentLoading from '@/components/ContentLoading'

export default {
  name: 'ModalConfirmation',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
    ContentLoading,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      loading: false,
    }
  },

  methods: {
    closeModal () {
      this.$emit('update:active', false)
    },

    sendToOrder (product) {
      this.loading = true
      logger.debug('Sending to order: ', product)
      this.$emit('toOrder', product, 'confirm')
    },
  },

}
</script>

<template>
  <modal :active="active" @close="closeModal" :close="false" :width="'500px'" :class="$classes['modal']">
    <template v-if="active">
      <modal-header title="Aviso" />
      <content-loading v-if="active && loading" :height="'100px'" />
      <div v-else>
        <div>
          <span :class="$classes['message']">Produto com apenas </span>
          <span :class="$classes['message-bold']">{{ stock }}</span>
          <span :class="$classes['message']"> em sua filial preferencial! Deseja adicionar ao pedido mesmo assim?</span>
        </div>
        <div :class="$classes['actions']">
          <action variant="flat" color="secondary" uppercase bold @click="closeModal">
            <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
            Cancelar
          </action>
          <action variant="contained" color="primary" :loading="loading" :disabled="loading"
            @click="sendToOrder(product)">
            Confirmar
          </action>
        </div>
      </div>
    </template>
  </modal>
</template>

<style module>
.modal {
  z-index: 101;
}

.message {
  margin-top: 10px;
  font-size: 14px;
  color: #292929;
}

.message-bold {
  font-size: 16px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.8);
}

.actions {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
