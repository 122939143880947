<script>
import { mapActions } from 'vuex'
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import routes from '@/router/index'

export default {
  name: 'ModalCheckoutOrder',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    order: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      loading: false,
    }
  },

  methods: {
    ...mapActions('orders', ['checkoutOrder']),

    closeModal () {
      this.$emit('update:active', false)
    },

    async handleCheckoutOrder () {
      this.loading = true
      await this.checkoutOrder({ id: this.order.id }).then(() => {
        this.loading = false
        if (routes.currentRoute.name !== 'orders') {
          routes.replace({ name: 'orders' })
        }
        this.$emit('updateOrders')
      })
      this.closeModal()
    },
  },
}
</script>

<template>
  <modal :active="active" @close="closeModal" :width="'500px'">
    <template v-if="active">
      <modal-header title="Finalizar pedido" message="Você confirma a finalização do pedido?" />
      <div :class="$classes['order']">
        <span :class="$classes['order-label']">Pedido</span>
        <span :class="$classes['order-value']">{{ order.name }}</span>
      </div>
      <div :class="$classes['total']">
        <span :class="$classes['total-label']">Total</span>
        <span :class="$classes['total-value']">{{ order.total | currency }}</span>
      </div>
      <div :class="$classes['actions']">
        <action variant="flat" color="secondary" uppercase bold @click="closeModal">
          <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
          Cancelar
        </action>
        <action variant="contained" color="primary" :loading="loading" :disabled="loading" @click="handleCheckoutOrder">
          Confirmar
        </action>
      </div>
    </template>
  </modal>
</template>

<style module>
.order {
  margin-bottom: 30px;
}

.order-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.8);
  margin-bottom: 10px;
}

.order-value {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: rgba(41, 41, 41, 0.8);
}

.total {
  display: flex;
  align-items: center;
}

.total-label {
  font-size: 14px;
  text-transform: uppercase;
  color: #a1a1a1;
  margin-right: 15px;
}

.total-value {
  font-size: 14px;
  font-weight: 600;
}

.actions {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
}
</style>
