<script>
export default {
  name: 'FormLabelMessage',

  props: {
    validation: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    hasError () {
      return (this.validation && this.validation.$error) || false
    },

    message () {
      if (!this.validation.required && this.validation.required !== undefined) {
        return 'Campo obrigatório'
      }
      if (!this.validation.email && this.validation.email !== undefined) {
        return 'E-mail inválido'
      }
      if (!this.validation.cpfCnpj && this.validation.cpfCnpj !== undefined) {
        return 'CPF/CNPJ inválido'
      }
      if (!this.validation.sameAsPassword && this.validation.sameAsPassword !== undefined) {
        return 'Senha não confere'
      }
      return ''
    },
  },
}
</script>

<template>
  <span
    v-if="hasError"
    :class="$classes['form-message']"
  >
    {{ message }}
  </span>
</template>

<style module>
.form-message {
  font-size: 12px;
  color: rgba(209, 64, 64, 0.8);
  margin-left: -1px;
}
</style>
