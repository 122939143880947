<script>
import Modal from '@/components/Modal'
import VueImageZoomer from 'vue-image-zoomer'

export default {
  name: 'ModalProductImage',

  components: {
    Modal,
    VueImageZoomer,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      default: null,
    },
  },

  methods: {
    closeModal () {
      this.$emit('update:active', false)
    },
  },
}
</script>

<template>
  <modal :active="active" @close="closeModal">
    <vue-image-zoomer :regular="image" :zoom="image" :zoom-amount="2.5" img-class="img-fluid"
      :click-zoom="true"
      click-message="Clique com o mouse para ampliar"
      touch-message="Toque para ampliar">
    </vue-image-zoomer>
  </modal>
</template>

<style module></style>
