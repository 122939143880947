<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import InputField from '@/components/InputField'

export default {
  name: 'ModalNewOrder',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
    InputField,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      loading: false,
      formData: {
        name: null,
        obs: null,
      },
    }
  },

  validations: {
    formData: {
      name: {
        required,
      },
    },
  },

  methods: {
    ...mapActions('orders', ['addNewOrder']),

    closeModal () {
      this.$emit('update:active', false)
      this.formData = {
        name: null,
        obs: null,
      }
      this.$v.$reset()
    },

    handleAddOrder (e) {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.loading = true
      this.addNewOrder(this.formData).then(() => {
        this.$data.text = ''
        this.loading = false
        this.$emit('updateOrders')
        this.closeModal()
      })
    },
  },
}
</script>

<template>
  <modal :active="active" @close="closeModal" :width="'500px'">
    <modal-header title="Adicionar novo pedido" />
    <form @submit.prevent='handleAddOrder()'>
      <input-field label="Título do novo pedido" :label-uppercase="false" placeholder="Escreva aqui"
        v-model="formData.name" :validation="$v.formData.name" />
      <input-field label="Observações" :label-uppercase="false" placeholder="Escreva aqui" v-model="formData.obs"
        :validation="$v.formData.obs" />
      <div :class="$classes['form-actions']">
        <action variant="flat" color="secondary" type="button" uppercase bold @click="closeModal">
          <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
          Cancelar
        </action>
        <action variant="contained" color="primary" type="submit" :loading="loading" :disabled="loading">
          Adicionar
        </action>
      </div>
    </form>
  </modal>
</template>

<style module>
.form-actions {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
</style>
