<script>
import { mapActions, mapGetters } from 'vuex'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import FilterSearch from '@/components/FilterSearch'
import ProductDetail from '@/components/ProductDetail'
import OrderCart from '@/components/OrderCart'
import ModalNewOrder from '@/components/ModalNewOrder'
import ContentLoading from '@/components/ContentLoading'
import ModalHeader from '@/components/ModalHeader'
import ModalPecas from '@/components/ModalPecas'
import Table from '@/components/Table.vue'
import ProgressBar from 'vue-simple-progress'
import logger from '@/logger/index'
import TabNav from '@/components/TabNav.vue'
import Tab from '@/components/Tab.vue'
import VehiclePlateSearch from './VehiclePlateSearch.vue'
import recaptcha from '@/services/recaptcha'
import routes from '@/router/index'
import NoResultsMessage from '@/components/NoResultsMessage.vue'

export default {
  name: 'Products',

  components: {
    Action,
    Icon,
    FilterSearch,
    ProductDetail,
    OrderCart,
    ModalNewOrder,
    ContentLoading,
    ModalHeader,
    ModalPecas,
    Table,
    ProgressBar,
    TabNav,
    Tab,
    VehiclePlateSearch,
    NoResultsMessage,
  },

  data () {
    return {
      selected: 0,
      gtoken: '',
      dados: null,
      inProgress: false,
      itens: [],
      loading: false,
      loadingParts: false,
      loadingProduct: false,
      loadingProgressBar: false,
      loadingSearch: false,
      loadingTable: false,
      loadingTableStock: false,
      opacity: 0,
      progress: 0,
      productNotFound: false,
      quantity: 1,
      search: null,
      selectedPartProducts: null,
      showModalNewOrder: false,
      showModal: false,
      showModalAlert: false,
      visibleTable: 0,
      filters: [
        {
          label: 'Descrição, Original ou Indústria',
          value: 'descricao',
        },
        {
          label: 'Código Scherer',
          value: 'codigo',
        }
      ],
      filter: 'descricao',
    }
  },

  computed: {
    ...mapGetters('orders', ['orders', 'getSelectedOrder', 'getStatusTable']),
    ...mapGetters('products', ['getAllProducts', 'getSelectedProduct', 'getPartProducts', 'getSelectedParts', 'getAllRelatedProducts', 'getAllParts']),
    ...mapGetters('userPermissions', ['getBlockSale']),

    menuTab () {
      return [
        {
          text: 'PRODUTOS',
        },
        {
          text: 'VEÍCULOS',
        }
      ]
    },

    areArraysEqual () {
      return JSON.stringify(this.getAllParts) === JSON.stringify(this.getAllRelatedProducts)
    },

    partsPriceRule () {
      return this.selectedPartProducts?.price.find(price => this.quantity >= price.min_limit && this.quantity <= price.max_limit) || {}
    },

    loadingProgress () {
      var percentage = (100 / this.itens.length)
      return percentage
    },

    progressString () {
      let string = parseInt(this.progress)
      return `${string}%`
    },
  },

  filters: {
    formatCode (value) {
      value = value.toString().split('.0')
      return value[0]
    },
  },

  async mounted () {
    this.loading = true
    if (!this.getSelectedOrder) {
      await this.getOrderCart().then(() => {
        if (this.orders.filter(iten => iten.status === 'Em andamento').length < 1) {
          this.inProgress = false
          this.setFeedback({ title: 'Inicie um novo pedido', message: '', type: 'success' })
        }
      })
      this.loading = false
    } else if (this.loading) {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },

  updated () {
    if (this.orders.filter(iten => iten.status === 'Em andamento').length > 0) {
      this.inProgress = true
    }
  },

  methods: {
    ...mapActions(['setHeaderBack', 'setFeedback']),
    ...mapActions('orders', ['addProduct', 'removeStatusTable', 'updateProductQuantity', 'getOrderCart', 'removeSelectOrder']),
    ...mapActions('products', ['searchProducts', 'getProduct', 'getPartsProducts', 'selectProduct', 'getProductDiscreet', 'checkPartsProductPrice', 'getParts', 'getRelatedProducts', 'restoreRelatedProducts', 'removeSelectedParts']),

    async handleOrderSearch (search) {
      const token = await recaptcha.getRecaptchaToken()
      if (!search) {
        this.loadingSearch = false
        return
      }
      this.loadingSearch = true
      if (this.filter === 'codigo') {
        const product = await this.getProduct({
          search,
        })
        this.productNotFound = product.notFound ?? false
        this.loadingSearch = false
      } else {
        const product = await this.searchProducts({
          search,
          type: this.filter,
          recaptcha: token,
        })
        this.productNotFound = product.notFound ?? false
        this.loadingSearch = false
      }
    },

    handleOrderFilter (filterValue) {
      this.filter = filterValue
    },

    handleSelectProduct (product) {
      var search = product.id
      this.loadingSearch = true
      this.getProduct({
        search,
      }).then(() => {
        this.loadingSearch = false
      })
    },

    newOrder () {
      this.showModalNewOrder = true
    },

    async openModal () {
      this.showModal = true

      if (this.getSelectedProduct && this.visibleTable === 2) {
        logger.debug('alterado para produto: ', this.getSelectedProduct.code)
        this.getSelectedParts && logger.debug('conjunto atual: ', this.getSelectedParts?.id)
        logger.debug('getPartProducts: ', this.getPartProducts)
        let foundProduct = this.getPartProducts.find(product => parseInt(product.set_id) === this.getSelectedParts?.id && parseInt(product.product_id) === this.getSelectedProduct.code)
        // logger.debug('foundProduct: ', foundProduct)
        if (!(Array.isArray(this.getPartProducts) && (foundProduct))) {
          logger.debug('esse produto NÃO é do mesmo conjunto |', foundProduct?.set_id, this.getSelectedParts?.id, foundProduct?.product_id, this.getSelectedProduct.code)
          this.visibleTable = 1
        } else {
          logger.debug('esse produto é do mesmo conjunto |', foundProduct?.set_id, this.getSelectedParts?.id, foundProduct?.product_id, this.getSelectedProduct.code)
        }
      }

      if (this.visibleTable === 2) { return }

      if (this.getSelectedProduct) {
        this.loadingTable = true
        this.dados = this.getSelectedProduct.id
        await this.getRelatedProducts({
          search: this.dados,
        })
        this.dados = null
        this.removeSelectedParts()
        this.loadingTable = false
        this.visibleTable = 1
        this.opacity = 1
      }
      this.setSelected(0)
    },

    closeModal () {
      this.showModal = false
      this.showModalAlert = false
      if (routes.currentRoute.name !== 'products') {
        return routes.push({ name: 'products' })
      }
    },

    cancelActionModal () {
      this.showModalAlert = false
      this.visibleTable = 2
      this.closeModal()
    },

    async handlePartsSearch (searchText) {
      if (!searchText) {
        this.restoreRelatedProducts()
        this.loadingParts = false
        return
      }
      this.search = searchText?.toUpperCase()
      this.loadingParts = true
      if (this.search.length >= 3) {
        await this.removeSelectedParts()
        await this.loadTable({ search: this.search })
      }
      setTimeout(() => {
        this.loadingParts = false
      }, 1000)
    },

    loadTable (search) {
      this.getParts(search)
        .then(() => {
          this.visibleTable = 1
        })
    },

    async loadingTableParts () { // NOTE: método carrega os produtos do Conjunto
      this.opacity = 0
      this.loadingTableStock = true
      await this.getPartsProducts({ parts: this.getSelectedParts.id })
        .then(() => {
          this.visibleTable = 2
          this.loadingTableStock = false
          this.handleFeedbackStock()
        })
    },

    handleFeedbackStock () {
      setTimeout(() => {
        if (this.$refs.partsTable?.withoutStock === true) {
          this.setFeedback({ title: 'Não possui alguns produtos disponível', message: 'Desculpe, mas não há mais unidades de alguns produtos em estoque.', type: 'error' })
        }
      }, 500)
    },

    handleCloseTable (payload) {
      this.showModal = false
      logger.debug(payload)
      this.dados = payload.product_id
      this.handleSelectProduct({ id: payload.product_id })
    },

    async backTable () {
      this.opacity = 1
      this.removeSelectedParts()
      if (this.dados !== null) {
        await this.getRelatedProducts({
          search: this.dados,
        })
        this.dados = null
      }
      this.visibleTable = 1
    },

    async handleModalAddProduct () {
      this.progress = 0
      this.loadingProduct = true
      this.itens = this.$refs.partsTable.selectProduct

      if (this.$refs.partsTable.withoutStock) {
        this.showModalAlert = true
        this.visibleTable = 3
        this.loadingProduct = false
        logger.debug('product recebendo withOutStock: ', this.$refs.partsTable.withoutStock)
        logger.debug('Abrindo o modal de confirmação...')
      } else {
        logger.debug('else handleModalAddProduct')
        await this.addProductInOrderCart(this.itens)
      }
    },

    async addProductInOrderCart (items) {
      logger.debug('/--------------------------------')
      logger.debug('Iniciando addProductInOrderCart..')
      this.showModalAlert = false
      this.loadingProgressBar = true
      this.visibleTable = 4
      let cont = 0
      // console.time()
      for (const item of items) {
        await this.sendToOrder(item)
        cont++
        this.progress = (cont / items.length) * 100
      }

      setTimeout(() => { // TODO: verificar isso aqui
        if (this.progress > 99.7) {
          this.loadingProgressBar = false
          this.showModal = false
          this.visibleTable = 0
          // console.timeEnd()
          this.setFeedback({ title: 'Produtos adicionados com sucesso', message: '', type: 'success' })
          logger.debug('/--------------------------------')
        }
      }, 1000)

      this.loadingProduct = false
    },

    async sendToOrder (search) {
      logger.debug('Iniciando sendToOrder..: ', search)
      await this.checkPartsProductPrice({ id: search })
      await this.getProductDiscreet({ search })

      this.selectedPartProducts = this.getSelectedParts.products.find(product => product.id === search)

      if (this.selectedPartProducts.id !== 0) {
        await this.validateProduct(this.selectedPartProducts)
      } else {
        this.setFeedback({ title: 'Não possui este produto Cadastrado', message: '', type: 'error' })
        this.loadingProduct = false
      }
    },

    async validateProduct (product) {
      logger.debug('Iniciando validateProduct...')
      if (product.id !== 0) {
        logger.debug('Produto válido...')
        await this.validatePriceProduct(product)
      } else {
        this.setFeedback({ title: 'Não possui este produto Cadastrado', message: '', type: 'error' })
        this.loadingProduct = false
      }
    },

    async validatePriceProduct (product) {
      logger.debug('Iniciando validatePriceProduct...')
      if (this.partsPriceRule.price > 0) {
        logger.debug('Preço do produto validado...')
        product.quantity = this.getPartProducts.find(item => item.product_id === product.id).quantity // NOTE: a quantidade do item no Conjunto

        await this.$refs.productDetail.checkBlockSale({
          list_type: this.partsPriceRule.list_type,
          price: this.partsPriceRule.price,
          quantity: product.quantity,
          product: product,
          insertionQueue: true,
        })
      } else {
        this.setFeedback({ title: 'Desculpe, produto indisponível no momento', message: '', type: 'error' })
        this.loadingProduct = false
      }
    },

    setSelected (tab) {
      this.selected = tab
    },

    hasValidStock (products) {
      return Array.isArray(products) && products.length > 0 && this.hasStock(products[0])
    },
    hasStock (product) {
      return product && Array.isArray(product.stock) && product.stock.length > 0
    },
  },

  watch: {
    showModal () {
      if (this.getstatusTable === true) {
        this.removeStatusTable()
        this.visibleTable = 0
      }
    },
    $route () {
      if (this.$route.meta.openModal) {
        this.openModal()
      }
    },
  },
}
</script>

<template>
  <div :class="$classes['search-content']">
    <div :class="$classes['header']">
      <span :class="$classes['search-title']">Pesquisas</span>
      <action variant="outlined" v-if="!$media.mobile" color="secondary" :class="$classes['header-btn']"
        @click="newOrder">
        <icon name="clipboard-list" btnIcon />Iniciar Novo Pedido
      </action>
      <action variant="outlined" v-else mobile color="secondary" :class="$classes['header-btn']" @click="newOrder">
        <icon name="clipboard-list" btnIcon />Novo Pedido
      </action>
    </div>
    <div :class="$classes['main-content']">
      <TabNav :tabs="menuTab" :selected="selected" @selected="setSelected" :class="$classes['tab-label']">
        <Tab :isSelected="selected === 0" :class="{ selectedTab: selected === 0 }">
          <div :class="{[$classes['selected-tab']]: true, [$classes['order-cart-active']]: orders.length !== 0}">
            <div :class="$classes['products']">
              <div :class="$classes['main-content']">
                <div :class="$classes['content']">
                  <filter-search placeholder="Escreva aqui" :filters="filters" :default-value="filters[0].value"
                    :results="getAllProducts" :loading="loadingSearch" @search="handleOrderSearch"
                    @filter="handleOrderFilter" @select="handleSelectProduct" :disable="!inProgress" />
                  <product-detail v-if="getSelectedProduct && inProgress" @open="openModal" ref="productDetail" />
                  <no-results-message v-else-if="productNotFound" :title="'Não encontramos resultados para a sua busca.'"
                    :message="'Tente usar palavras-chave diferentes ou mais genéricas'" />
                  <no-results-message v-if="orders.length === 0 && getSelectedOrder" img="clipboard-list" :iconWidth="'64px'" :title="'Inicie um novo pedido.'"
                  :message="'Não encontramos pedidos criados recentemente. Comece agora mesmo um novo pedido'" />
                  <content-loading v-if="loading" />
                </div>
              </div>

              <modal-pecas v-show="showModal" :active.sync="showModal" @close="closeModal" :alert.sync="showModalAlert"
                :visible="visibleTable">
                <template v-slot:header>
                  <h5 :class="$classes['header-title']" v-if="visibleTable === 1 || visibleTable === 0">
                    Conjunto de Peças
                  </h5>
                  <modal-header title="Aviso" v-if="visibleTable === 3" />
                  <action v-if="visibleTable === 2" variant="flat" color="secondary" uppercase
                    :class="$classes['order-back-btn']" @click="backTable">
                    <icon name="long-arrow-alt-left" btnIcon :class="$classes['order-back-btn-icon']" />
                    Voltar
                  </action>
                  <action v-if="visibleTable === 2" variant="contained" color="primary"
                    :class="$classes['product-addOrder-btn']" :loading="loadingProduct" @click="handleModalAddProduct">
                    <icon name="plus" btnIcon />
                    Adicionar ao Pedido
                  </action>
                  <filter-search v-if="visibleTable < 3" :class="$classes['product-detail']" placeholder="Escreva aqui"
                    @search="handlePartsSearch" :loading="loadingParts" />
                </template>
                <template v-slot:title>
                  <span :class="$classes['product-info-label']" v-if="visibleTable === 1"
                    :style="{ opacity: opacity, visibility: 'visible' }">
                    <span v-if="getSelectedProduct && areArraysEqual"
                      :class="[$classes['product-info-label'], $classes['show']]">Conjunto relacionado ao código: </span>
                    <span v-if="getSelectedProduct && areArraysEqual" :class="$classes['label-color']">{{
                      getSelectedProduct.id }}</span>
                  </span>
                  <span :class="[$classes['product-info-label'], $classes['show']]" v-if="JSON.stringify(getSelectedParts) !== '{}' &&
                    visibleTable != 1 && visibleTable != 3">
                    <span v-if="getSelectedParts">Itens do Conjunto: </span>
                    <span v-if="getSelectedParts" :class="$classes['label-color']">{{ getSelectedParts.id }}</span> -
                    {{ getSelectedParts.description }}
                  </span>
                </template>
                <template v-slot:body>
                  <content-loading v-if="loadingTable || loadingParts" :height="'100px'"></content-loading>
                  <Table v-else-if="visibleTable === 1 && getAllParts.length != 0" @newTable="loadingTableParts"
                    :filter="'selectedParts'" :visibleCheck="false" :content="getAllParts" :titles="{
                      id: { title: 'Código' },
                      description: { title: 'Descrição' },
                    }"></Table>
                  <span v-else-if="visibleTable === 1 && getAllParts.length === 0" :class="[
                    $classes['product-info-label-alert'],
                    $classes['not-found'],
                  ]">
                    O código do produto selecionado não possui conjuntos
                    relacionados.
                  </span>
                  <content-loading v-if="visibleTable === 2 && loadingTableStock" :height="'100px'"></content-loading>
                  <Table v-else-if="visibleTable === 2 && hasValidStock" ref="partsTable" :filter="'selectedParts'"
                    @closeTable="handleCloseTable($event)" :visibleCheck="true" :content="getPartProducts" :titles="{
                      product_id: { title: 'Produto' },
                      quantity: { title: 'Qtd' },
                      industry_id: { title: 'Indústria' },
                      description: { title: 'Descrição' },
                    }"></Table>
                  <template v-if="visibleTable === 3">
                    <div>
                      <div>
                        <span :class="$classes['product-info-label-alert']">Nem todos os produtos do conjunto de peças
                          estão em
                          estoque.</span>
                        <span :class="$classes['product-info-label-alert']">Deseja continuar mesmo assim ?</span>
                      </div>
                      <div :class="$classes['product-stock-actions-alert']">
                        <action variant="flat" color="secondary" uppercase bold @click="cancelActionModal">
                          <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
                          Cancelar
                        </action>
                        <action variant="contained" color="primary" :loading="loading" :disabled="loading"
                          @click="addProductInOrderCart(itens)">
                          Confirmar
                        </action>
                      </div>
                    </div>
                  </template>
                  <progress-bar :val="progress" :text="progressString" :title="progressString"
                    v-if="loadingProgressBar" />
                </template>
              </modal-pecas>
            </div>
          </div>
        </Tab>
        <Tab :isSelected="selected === 1">
          <div :class="{[$classes['selected-tab']]: true, [$classes['order-cart-active']]: orders.length !== 0}">
            <VehiclePlateSearch @changeTab="selected = $event"></VehiclePlateSearch>
          </div>
        </Tab>
      </TabNav>
      <modal-new-order :active.sync="showModalNewOrder" />
      <div :class="$classes['sidebar']">
        <order-cart v-if="orders.length" @changeTab="selected = $event" />
      </div>
    </div>
  </div>
</template>

<style module>
.products {
  padding: 45px 30px 30px 0px;
}

.selected-tab {
  background-color: #f3f3f4;
}

.order-cart-active {
  margin-right: 342px;
}

.tab-label {
  font-size: 18px;
  color: #292929;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  width: -webkit-fill-available;
}

.product-addOrder-btn {
  height: 40px;
  padding: 0 15px;
  float: right;
}

.product-detail {
  margin-top: 30px;
}

.product-info-content {
  margin-top: 2rem;
  padding: 40px;
  text-align: center;
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 10px;
}

.product-info-label {
  /* display: block; */
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #292929;
  visibility: hidden;
  margin-bottom: 10px;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.product-info-label.show {
  visibility: visible;
  opacity: 1;
}

.product-info-label .label-color {
  color: #4a94d4;
}

.product-info-label-alert {
  display: block;
  text-transform: initial;
  font-size: 14px;
  line-height: 18px;
  color: #292929;
  margin-bottom: 10px;
}

.product-info-label-alert .label-color {
  color: #4a94d4;
}

.product-info-label-alert.not-found {
  color: #333333;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 10px 20px;
  line-height: 1.5;
}

.product-stock-actions-alert {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 3px;
}

.icon-wrapper.not-found {
  width: auto;
  height: 7rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #292929;
}

.main-content {
  /* display: flex; */
  justify-content: space-between;
}

.content {
  flex: 1;
  margin-right: 25px;
}

.search-content {
  padding: 25px 25px 0px 45px;
  display: block;
  justify-content: space-between;
}

.search-title {
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #292929;
}

.sidebar {
  width: 342px;
  position: absolute;
  right: 25px;
  top: 250px;
  z-index: 90;
}

@media (max-width: 1280px) {
  .products {
    padding-bottom: 100px;
  }

  .selected-tab {
    background-color: #f3f3f4;
    margin-right: 0px;
  }

  .content {
    margin-right: 0;
  }

  .sidebar {
    position: fixed;
    top: auto;
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 767px) {
  .search-content {
    padding: 10px;
    margin-bottom: 55px;
    display: block;
    justify-content: space-between;
  }

  .products {
    padding: 25px 5px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
  }

  .header-btn {
    order: 1;
    align-self: center;
    max-width: 270px;
  }

  .sidebar {
    width: auto;
    left: 10px;
    right: 10px;
  }
}
</style>
