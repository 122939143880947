<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import NumberField from '@/components/NumberField'
import ContentLoading from '@/components/ContentLoading'
import logger from '@/logger/index'

export default {
  name: 'ModalUpdateCartItemQuantity',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
    NumberField,
    ContentLoading,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    currentQuantity: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      loading: false,
      childQuantity: this.currentQuantity,
    }
  },

  computed: {
    ...mapGetters('orders', ['getSelectedOrder']),

    productInOrderCart () {
      return this.getSelectedOrder?.products.find(item => item.id === this.product.id) || {}
    },
  },

  watch: {
    currentQuantity (newValue) {
      this.childQuantity = newValue
    },
  },
  methods: {
    ...mapActions(['setFeedback']),
    ...mapActions('orders', ['updateProductQuantity']),

    closeModal () {
      this.$emit('update:active', false)
    },

    handleUpdateQuantity (product) {
      this.loading = true
      logger.debug('product_in_OrderCart: ', product)
      const item = { product: product, quantity: this.childQuantity }
      this.$emit('updateQuantity', item)
      this.closeModal()
      this.loading = false
    },
  },
}
</script>

<template>
  <modal :active="active" :close="false" :class="$classes['modal']">
    <template v-if="active">
      <modal-header title="Produto já adicionado ao carrinho" message="Deseja atualizar a quantidade do item?" />
      <content-loading v-if="active && loading" :height="'100px'" />
      <div v-else>
        <div :class="$classes['product']">
          <span :class="$classes['product-value']">{{ product.description }}</span>
        </div>
        <div :class="$classes['product-quantity-label']">
          <span :class="$classes['text']">Qtd.:</span>
          <span :class="$classes['red']">{{ productInOrderCart.quantity }}</span>
          <icon name="long-arrow-alt-right" :class="$classes['arrow']" />
          <span :class="$classes['text']">Qtd.:</span>
          <div :class="$classes['product-quantity-price']">
            <div :class="$classes['number-field']">
              <div :class="[$classes['number-field-wrapper'], {
                [$classes['has-changed']]: childQuantity === currentQuantity
              }]">
                <number-field v-model="childQuantity" :class="$classes['number-field-input']"
                  :text-color="(childQuantity || currentQuantity) != productInOrderCart.quantity ? '#4A94D4' : '#292929'" />
                <action :class="{
                  [$classes['number-field-action']]: true,
                  [$classes['is-disabled']]: childQuantity <= 0
                }" @click="handleUpdateQuantity(productInOrderCart)">
                  <icon name="check" :class="$classes['number-field-icon']" />
                </action>
              </div>
            </div>
          </div>
        </div>
        <!-- <div :class="$classes['product-price']">
          <span :class="$classes['product-price-label']">Subtotal:</span>
          <span :class="$classes['product-price-value']">{{ quantity * product.price | currency }}</span>
        </div> -->
        <div :class="$classes['actions']">
          <action @click="closeModal" variant="flat" color="secondary" :loading="loading"
            :class="$classes['product-button']" :disabled="loading">
            <icon name="times" btnIcon />
            Não Alterar
          </action>
        </div>
      </div>
    </template>
  </modal>
</template>

<style module>
.modal {
  z-index: 100;
}

.actions {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
}

.number-field {
  width: 122px;
}

.number-field-wrapper {
  display: inline-flex;
  align-items: center;
  border: 1px solid #A1A1A1;
  border-radius: 2px;
}

.number-field-wrapper.has-changed {
  border-color: #4A94D4;
}

.number-field-wrapper.is-disabled {
  border-color: rgba(162, 162, 162, 0.25);
}

.number-field-wrapper .number-field-input {
  background: #fff;
  border: 0;
  height: 28px;
  max-width: 90px;
  flex: 1;
}

.number-field-action {
  width: 30px;
  height: 28px;
  background: #4A94D4;
  color: #fff;
}

.number-field-action.is-disabled {
  background-color: #DBDBDB;
}

.number-field-icon {
  width: 16px;
  height: 16px;
}

.product {
  margin: 30px 0;
}

.product-label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: rgba(41, 41, 41, 0.8);
  line-height: 20px;
  margin-bottom: 10px;
}

.product-value {
  display: block;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: rgba(41, 41, 41, 0.8);
}

.product-quantity {
  padding-top: 0px;
}

.product-quantity-label {
  margin: 30px 0;
  font-size: 17px;
  text-transform: uppercase;
  color: #a1a1a1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-quantity-label .text {
  margin-right: 10px;
}

.product-quantity-label .red {
  color: #D14040;
  margin-right: 30px;
}

.product-quantity-label .blue {
  color: #4A94D4;
  margin-right: 30px;
}

.product-another-quantity {
  height: 90px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.product-another-quantity .checkbox {
  padding: 5px;
}

.arrow {
  margin-right: 30px;
}

.product-button {
  margin-right: 15px;
}
</style>
