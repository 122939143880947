
const getRecaptchaToken = async function () {
  const token = await new Promise((resolve) => {
    window.grecaptcha.execute('6LfxZF4kAAAAAD9lJib9WrT6r_rEySpFF8bXalo8', { action: 'searchProducts' })
      .then((token) => {
        resolve(token)
      })
  })
  return token
}

export default {
  getRecaptchaToken,
}
