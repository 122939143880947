<script>
import FormItem from '@/components/FormItem'
import FormInputWrapper from '@/components/FormInputWrapper'
import FormLabel from '@/components/FormLabel'
import FormLabelMessage from '@/components/FormLabelMessage'
import Icon from '@/components/Icon'

export default {
  name: 'SelectField',

  components: {
    FormItem,
    FormInputWrapper,
    FormLabel,
    FormLabelMessage,
    Icon,
  },

  props: {
    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    validation: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      isFocused: false,
      isHover: false,
    }
  },

  computed: {
    hasError () {
      return (this.validation && this.validation.$error) || false
    },

    computedClasses () {
      return {
        [[this.$classes['form-select']]]: true,
        [[this.$classes['is-disabled']]]: this.disabled,
      }
    },

    inputName () {
      return `input-${this._uid}`
    },
  },

  methods: {
    handleOver () {
      this.isHover = true
    },

    handleLeave () {
      this.isHover = false
    },

    updateValue (value) {
      this.validation && this.validation.$touch()
      this.$emit('input', value)
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur (event) {
      this.isFocused = false
      this.validation && this.validation.$touch()
    },
  },
}
</script>

<template>
  <form-item>
    <form-label v-if="label" :text="label" :input-name="inputName" :error="hasError" :disabled="disabled"
      :validation="validation">
      <form-label-message :validation="validation" />
    </form-label>
    <form-input-wrapper :focus="isFocused" :error="hasError" :disabled="disabled">
      <select :id="inputName" :placeholder="placeholder" :disabled="disabled" :readonly="readonly"
        :class="computedClasses" :value="value" @mouseover="handleOver" @mouseleave="handleLeave" @focus="updateFocus"
        @blur="updateBlur" @change="updateValue($event.target.value)">
        <option value="" selected v-if="placeholder">{{ placeholder }}</option>
        <slot />
      </select>
      <icon name="caret-down" :class="{
        [$classes['select-icon']]: true,
        [$classes['is-hover']]: isHover,
        [$classes['is-disabled']]: disabled,
      }" />
    </form-input-wrapper>
  </form-item>
</template>

<style module>
.form-select {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 35px;
  flex: 1;
  appearance: none;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #292929;
  background: none;
}

.select-icon {
  position: absolute;
  top: 5px;
  right: 0;
  pointer-events: none;
  color: #A2A2A2;
}

.select-icon.is-hover {
  color: #292929;
}

.select-icon.is-disabled {
  color: #DBDBDB;
}
</style>
