<script>
import Action from '@/components/Action'
// import Icon from '@/components/Icon'
export default {
  name: 'TabNav',
  components: {
    Action,
    // Icon,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
  },

  methods: {
    setTab (tab) {
      this.$emit('selected', tab)
    },
  },
}
</script>

<template>
  <div :class="$classes['navbar']">
    <ul class="nav nav-underline" :class="$classes['custom-nav-underline']">
      <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
        <action class="nav-link" :class="{
          [$classes['active']]: index === selected,
          [$classes['custom-nav-link']]: true,
          [$classes['custom-nav-show']]: true
        }" @click="setTab(index)">
          <!-- <span :class="$classes['menu-nav-icon-wrapper']">
            <icon name="license-plate" :class="$classes['menu-nav-icon']" />
          </span> -->
          <span>{{ tab.text }}</span>
        </action>
      </li>
    </ul>

    <slot></slot>
  </div>
</template>

<style module>
.navbar {
  background-color: #F3F3F4;
}

.custom-nav-underline {
  border-bottom: 1px solid #dbdbdb;
  gap: 2rem;
}

.custom-nav-link {
  background-color: #F3F3F4;
  border: 2px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  padding-bottom: 0.5rem;
  padding-top: 4vh;
  color: #666;
}

.custom-nav-link.active {
  background-color: #F3F3F4;
  border-bottom-color: currentColor;
  color: #4A94D4;
  font-weight: 600;
}

.custom-nav-link.show {
  padding: auto;
}

.custom-nav-link:not(.active):hover {
  /* background-color: #ececee; */
  border-bottom-width: 0.125rem;
  color: #292929;
}

.menu-nav-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  padding: 3px
}
</style>
