<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div v-if="isSelected">
    <slot></slot>
  </div>
</template>

<style module>
</style>
