<script>
import Icon from '@/components/Icon'

export default {
  name: 'RadioField',

  components: {
    Icon,
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: null,
    },

    isGroup: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: String,
      default: null,
    },
  },

  computed: {
    mutableValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('change', value)
      },
    },

    computedClasses () {
      return {
        [this.$classes['form-radio-item']]: true,
        [this.$classes['has-parent']]: this.isGroup,
        [this.$classes['is-disabled']]: this.disabled,
        [this.$classes['is-checked']]: this.isChecked,
      }
    },

    isChecked () {
      return this.modelValue === this.value
    },

    iconName () {
      return this.isChecked ? 'dot-circle' : 'circle'
    },
  },
}
</script>

<template>
  <div :class="computedClasses">

    <label
      :class="$classes['form-radio-label']"
    >
      <input
        type="radio"
        :class="$classes['form-radio']"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        v-model="mutableValue"
      >
      <icon
        :name="iconName"
        :class="$classes['form-radio-icon']"
      />
      <span
        v-if="label"
        :class="$classes['form-radio-label-text']"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<style module>
.form-radio-item {
  position: relative;
}

.form-radio-item + .form-radio-item {
  margin-top: 15px;
}

.form-radio-item.has-parent + .form-radio-item.has-parent {
  margin-top: 0;
  margin-left: 15px;
}

.form-radio {
  position: absolute;
  visibility: hidden;
}

.form-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #a1a1a1;
}

.form-radio-label-text {
  font-size: 16px;
  margin-left: 10px;
}

.form-radio-item .form-radio-icon {
  width: 18px;
  height: 18px;
}

.form-radio-item.is-checked .form-radio-label {
  color: #292929;
}

.form-radio-item.is-disabled .form-radio-label {
  cursor: not-allowed;
  color: #49507C;
  opacity: 0.8;
}

@media (max-width: 767px) {
  .form-radio-item.has-parent + .form-radio-item.has-parent {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}
</style>
