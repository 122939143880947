<script>
import { mapActions, mapGetters } from 'vuex'
import logger from '@/logger/index'

export default {

  props: {
    content: {
      type: Array,
      default: () => [],
    },

    titles: {
      type: Object,
      default: () => ({}),
    },

    visibleCheck: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      selected: [],
      selectAll: false,
      withoutStock: false,
    }
  },
  computed: {
    ...mapGetters('orders', ['orders']),
    ...mapGetters('products', ['getSelectedParts', 'getPartProducts']),
    ...mapGetters('userPermissions', ['getFavoriteCompany', 'getBlockSale']),

    filteredData () { // NOTE: Filtra e retorna apenas os dados que serão mostrados na tabela
      let fields = Object.keys(this.titles)
      let filteredData = []
      this.content.map((item) => {
        let filteredItem = {}
        fields.forEach(field => {
          filteredItem[field] = item[field]
        })
        filteredData.push(filteredItem)
      })

      return filteredData
    },

    selectProduct () {
      return this.selected
    },
  },
  filters: {
    formatCode (value) {
      value = value.toString().split('.0')
      return value[0]
    },
  },
  methods: {
    ...mapActions(['setFeedback']),
    ...mapActions('products', ['addSelectedParts']),

    isObjectNullOrEmpty (obj) {
      return obj === null || Object.keys(obj).length === 0
    },

    selectedRow (item) {
      logger.debug('item: ', item)
      if (this.filter === 'selectedParts') {
        return this.partProducts(item)
      }
      this.$emit('returnRow', item)
    },

    partProducts (item) {
      if (!this.isObjectNullOrEmpty(this.getSelectedParts)) {
        return this.$emit('closeTable', item)
      }
      this.addSelectedParts(item)
      this.cleanSelection()
      logger.debug('withoutStock: ', this.withoutStock)
      this.withoutStock = false
      this.$emit('newTable')
    },

    cleanSelection () {
      this.selected = []
      this.selectAll = false
    },

    selectAllProductTable () {
      this.selected = []
      if (this.withoutStock === true && !this.selectAll) {
        for (let i in this.getSelectedParts.products) {
          const currentProduct = this.getSelectedParts.products[i]
          const stockSet = this.getPartProducts.find(item => item.product_id === currentProduct.product_id).quantity
          // logger.debug('stockSet: ', stockSet)
          if (this.getBlockSale !== 2 && (currentProduct.stock.reduce((total, stock) => total + stock.quantity, 0) >= stockSet)) {
            this.selected.push(currentProduct.product_id)
            // logger.debug(currentProduct.product_id)
          } else if (currentProduct.stock.find(stock => stock.company_id === this.getFavoriteCompany).quantity >= stockSet) {
            logger.debug('totalStock: ', currentProduct.stock.find(stock => stock.company_id === this.getFavoriteCompany).quantity, '| stockSet: ', stockSet, '| stockTotal > stockSet: ', currentProduct.stock.find(stock => stock.company_id === this.getFavoriteCompany).quantity > 0)
            this.selected.push(currentProduct.product_id)
          }
        }
        return
      }
      if (!this.selectAll) {
        for (let i in this.filteredData) {
          this.selected.push(this.filteredData[i].product_id)
        }
      }
    },

    validateStock (stockId) {
      if (!stockId || this.getBlockSale === 0) {
        return false
      }
      // Verifica se possui estoque do produto  do conjunto de peças selecionado
      if (stockId) {
        const product = this.getSelectedParts?.products.find(product => product.product_id === stockId)

        if (this.getBlockSale === 2) {
          if (product && product.stock) {
            const totalStock = product.stock.find(stock => stock.company_id === this.getFavoriteCompany).quantity
            // logger.debug('product: ', product.product_id, '| totalStock: ', totalStock)
            const stockSet = this.getPartProducts.find(item => item.product_id === product.product_id).quantity
            // logger.debug('stockSet: ', stockSet)
            const result = totalStock < stockSet
            // logger.debug('result: ', result)
            if (result === true) { this.withoutStock = true }
            return result
          }
        } else if (this.getBlockSale === 1) {
          if (product && product.stock) {
            const totalStock = product.stock.reduce((total, stock) => total + stock.quantity, 0)
            // logger.debug('product: ', product.product_id, '| totalStock: ', totalStock)
            const stockSet = this.getPartProducts.find(item => item.product_id === product.product_id).quantity
            // logger.debug('stockSet: ', stockSet)
            const result = totalStock < stockSet
            // logger.debug('result: ', result)
            if (result === true) { this.withoutStock = true }
            return result
          }
        }
      }
      return false
    },

    getClass (index) {
      return index === 'quantity'
    },

  },

}
</script>

<template>
  <div class="table-responsive" :class="$classes['div-table']">
    <table class="table table-hover" :class="$classes['table-style']">
      <thead :class="$classes['customThead']">
        <tr>
          <th>
            <label class="form-checkbox">
              <input type="checkbox" v-if="visibleCheck" v-model="selectAll" @click="selectAllProductTable()">
              <i class="form-icon"></i>
            </label>
          </th>
          <th v-for="(t, key) in titles" :key="key" :value="t">{{ t.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, chave) in filteredData" :key="chave"
          :class="{ [$classes['spaceUnder']]: true, [$classes['trfield-disabled']]: validateStock(obj.product_id) }">
          <td>
            <label class="form-checkbox">
              <input type="checkbox" v-if="visibleCheck" :value="obj.product_id" v-model="selected"
                :disabled="validateStock(obj.product_id)">
              <i class="form-icon"></i>
            </label>
          </td>
          <td v-for="(item, chaveValor) in obj" :key="chaveValor" @click="selectedRow(obj)"
            :class="{ [$classes['thfield-qtd']]: getClass(chaveValor) }">
            <span v-if="item">{{ item | formatCode }}</span>
            <span v-else>{{ item }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style module>
.div-table {
  border-width: 1px;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
  box-shadow: 0 5px 10px rgba(2, 2, 2, 0.25);
  background-color: white;
  max-height: calc(87vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 transparent;
}

.div-table th {
  padding: 2.2em 1.5em;
}

.table-style {
  width: 100%;
  font-size: 14px;
  background-color: white;
}

thead.customThead {
  position: sticky;
  top: 0;
  height: 3.5rem;
  background: rgb(255, 254, 254);
  box-shadow: 0 5px 10px 0 rgba(2, 2, 2, 0.25);
  letter-spacing: 0.1rem;
}

tr.spaceUnder>td {
  cursor: pointer;
  padding: 1.2em 1.5em;
  text-align: left;
}

.table-style input {
  width: 1.1rem;
  height: 1.1rem;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.thfield-qtd {
  text-align: center !important;
}

.trfield-disabled {
  /* rgba(231,234,236,0.2); */
  background-color: #DBDBDB;
  color: #858585;
  pointer-events: none;
}

.input-label-invisible {
  visibility: hidden;
  text-overflow: ellipsis;
}

.div-table::-webkit-scrollbar {
  width: 4px;
}

.div-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.div-table::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 5px;
}

.div-table::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
</style>
