<script>
import Loading from '@/components/Loading'

export default {
  name: 'ContentLoading',

  components: {
    Loading,
  },

  props: {
    height: {
      type: String,
      default: '400px',
    },
  },

  computed: {
    loadingStyle () {
      return { height: this.height }
    },
  },
}
</script>

<template>
  <div :class="$classes['content-loading']" :style="loadingStyle">
    <loading :class="$classes['loading']" />
  </div>
</template>

<style module>
.content-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  width: 50px;
  height: 50px;
}
</style>
