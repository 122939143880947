<script>
export default {
  name: 'NumberField',

  props: {
    placeholder: {
      type: String,
      default: null,
    },

    textColor: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Number,
      default: null,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: null,
    },

    step: {
      type: Number,
      default: null,
    },

    light: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses () {
      return {
        [[this.$classes['form-input']]]: true,
        [[this.$classes['is-disabled']]]: this.disabled,
        [[this.$classes['is-light']]]: this.light,
      }
    },
  },

  methods: {
    updateValue (value) {
      this.validation && this.validation.$touch()
      this.$emit('input', parseInt(value || 0))
    },
  },
}
</script>

<template>
  <input
    type="number"
    :class="computedClasses"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :value="value"
    :min="min"
    :max="max"
    :step="step"
    :style="{ color: textColor }"
    @input="updateValue($event.target.value)"
    />
</template>

<style module>
.form-input {
  border: 1px solid #A1A1A1;
  display: block;
  width: 100%;
  max-width: 100px;
  height: 30px;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #292929;
  background: none;
  border-radius: 2px;
  padding: 0 10px;
}

.is-disabled {
  cursor: text;
}

.is-light {
  border-color: #fff;
  background: #fff;
}
</style>
