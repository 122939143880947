<script>
// import Action from '@/components/Action'
// import Icon from '@/components/Icon'
import { mapActions, mapGetters, mapState } from 'vuex'
import InputField from '@/components/InputField.vue'
import SelectField from '@/components/SelectField'
import FilterSearch from '@/components/FilterSearch.vue'
import ContentLoading from '@/components/ContentLoading'
import Table from '@/components/Table.vue'
import logger from '@/logger/index'
import recaptcha from '@/services/recaptcha'

export default {
  name: 'VehiclePlateSearch',

  components: {
    InputField,
    SelectField,
    FilterSearch,
    Table,
    ContentLoading,
  },

  data () {
    return {
      plateNew: '',
      selectedDescription: '',
      results: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('vehicle', ['getModel', 'getListSuggestions', 'getRegistrationPlate', 'getSelectedSuggestion', 'getItemsTable']),
    ...mapState('vehicle', ['selectedSuggestion']),

    placaFormatada: {
      get: function () {
        var regex = /^([A-Z]{3})(\d{4})$/ // Valida a entrada do usuário
        var placa = this.plateNew.toUpperCase().replace(/[^A-Z0-9]/g, '') // Remove caracteres inválidos
        var placaFormatada = placa.replace(regex, '$1-$2') // Formata a placa com hífen
        return placaFormatada
      },
      set: function (value) {
        var regex = /^([A-Z]{3})(\d{4})$/ // Valida a entrada do usuário
        var placa = value.toUpperCase().replace(/[^A-Z0-9]/g, '') // Remove caracteres inválidos
        this.plateNew = placa.replace(regex, '$1$2') // Atualiza a propriedade "placa" sem hífen
      },
    },
  },

  created () {
    if (this.getSelectedSuggestion && this.selectedDescription !== this.getSelectedSuggestion) {
      this.selectedDescription = this.getSelectedSuggestion
    }
    if (this.getRegistrationPlate && this.plateNew !== this.getRegistrationPlate) {
      this.plateNew = this.getRegistrationPlate
    }
  },

  methods: {
    ...mapActions('products', ['getProduct']),
    ...mapActions(['setFeedback']),
    ...mapActions('vehicle', ['getVehiclePlate', 'addRegistrationPlate', 'selectSearchSuggestion', 'searchProducts']),

    searchInArray (payload) {
      // Transforma a string em letras maiúsculas e remove os espaços extras
      let string = payload?.toUpperCase().trim()

      // Verifica se a string está vazia
      if (!string) {
        this.results = this.getItemsTable
        return this.results
      }

      this.results = []
      const searchWords = string.split(' ').filter(word => word.trim() !== '')
      for (const obj of this.getItemsTable) {
        let found = true
        for (const searchWord of searchWords) {
          let matchFound = false
          for (const prop in obj) {
            if (typeof obj[prop] === 'string' && obj[prop].toUpperCase().indexOf(searchWord) !== -1) {
              matchFound = true
              break
            }
          }
          if (!matchFound) {
            found = false
            break
          }
        }
        if (found) {
          this.results.push(obj)
        }
      }
      return this.results
    },

    handlePlateSearch () {
      logger.debug('plateNew: ', this.plateNew)
      if (this.plateNew.length === 7) {
        if (this.getRegistrationPlate !== this.plateNew) {
          this.addRegistrationPlate(this.plateNew)
          this.getVehiclePlate(this.plateNew)
            .then(() => {
              this.results = null
              this.selectedDescription = null
              this.clearFilterSearch()
            })
            .catch(() => {
              this.setFeedback({ title: 'A placa de veículo é inválida', message: 'Por favor verifique e tente novamente.', type: 'error' })
            })
        }
        this.$refs.plateSearch.blur()
      }
    },

    clearFilterSearch () {
      this.$refs.filterSearchRef.$el.querySelector('input').value = ''
    },

    async handleProductSearch () {
      this.selectSearchSuggestion(this.selectedDescription)
      this.loading = true
      let search = this.getModel + ' ' + this.selectedDescription
      const token = await recaptcha.getRecaptchaToken()
      if (!search) {
        return
      }
      setTimeout(() => {
        this.searchProducts({
          search,
          type: 'descricao-veiculo',
          recaptcha: token,
        }).then(() => {
          this.results = this.getItemsTable
          this.loading = false
        })
      }, 500)
    },

    handleSelectProduct (product) {
      var search = product.CODIGO
      this.getProduct({
        search,
      }).then(() => {
        return this.$emit('changeTab', 0)
      })
    },

  },
}
</script>

<template>
  <div :class="$classes['vehicle']">
    <div :class="$classes['main-content']">
      <div id="content" :class="$classes['content']">
        <div :class="$classes['content-field']">
          <div :class="$classes['content-plate']">
            <label :class="$classes['label-info-plate']">Placa do veículo</label>
            <input ref="plateSearch" type="text" maxlength="7" :class="$classes['placa']" placeholder="XXX0000"
              v-model="placaFormatada" @input="handlePlateSearch()" autocomplete="off">
          </div>
          <div :class="$classes['content-input']">
            <label :class="$classes['label-info-value']">Modelo relacionado</label>
            <input-field :class="{
              [$classes['model']]: true,
              [$classes['label-info-input']]: true
            }" v-model="getModel" :readonly="true">
            </input-field>
            <label :class="$classes['label-info-value']">Sugestão de busca</label>
            <select-field v-model="selectedDescription" :placeholder="selectedDescription ? selectedDescription : ' '"
              @input="handleProductSearch" :class="{
                [$classes['suggestion']]: true,
                [$classes['label-info-input']]: true
              }
                " selected>
              <template v-for="( suggestion, index ) in  getListSuggestions ">
                <option :key="index" :value="suggestion.DESCRICAO_CURTA"> {{
                  suggestion.DESCRICAO_CURTA }}</option>
              </template>
            </select-field>
            <label :class="[$classes['label-info-value'], $classes['label-red']]">Filtro de busca</label>
            <filter-search ref="filterSearchRef" @search="searchInArray"
              :class="$classes['input-info-filterSearch']"></filter-search>
          </div>
        </div>
        <div>
          <content-loading v-if="loading" :height="'100px'" />
          <Table v-else-if="getModel && selectedDescription && !loading" :filter="'simple'"
            :content="!results ? getItemsTable : results" @returnRow="handleSelectProduct($event)" :titles="{
              CODIGO: { title: 'Código' },
              CODIGO_INDUSTRIA: { title: 'Cód. Indústria' },
              DESCRICAO_CURTA: { title: 'Descrição' },
              APLICACAO_COMPLETA: { title: 'Aplicação do veículo' },
              MSG_CONSULTA: { title: 'Avisos gerais' },
              DESCRICAO_DETALHE: { title: 'Detalhes do item' },
            }
              "></Table>
        </div>
      </div>
    </div>
  </div>
</template>

<style module>
.vehicle {
  padding: 45px 30px 30px 0px;
}

.header-title {
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #292929;
  visibility: hidden;
}

.main-content {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  width: auto;
  flex: 1;
}

.content-plate {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: auto;
  padding-bottom: 25px;
}

.content-field {
  flex: 1;
  display: flex;
  width: auto;
  padding-bottom: 50px;
}

.content-input {
  width: -webkit-fill-available;
  padding-left: 30px;
}

input[type="text"].placa {
  background: url('~@/assets/images/plate-vehicle-blank.png') no-repeat center center;
  background-size: cover;
  padding-top: 2vh;
  border: none;
  color: black;
  width: 250px;
  height: 100px;
  font-size: 36px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 4px;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
}

.label-info-value {
  padding: 10px 10px 10px 0px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  /*line-height: 18px;*/
  color: #292929;
  text-transform: uppercase;
  text-align: initial;
  line-height: 15px;
  letter-spacing: 0.1em;
}

.label-info-plate {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  /*line-height: 18px;*/
  color: #292929;
  text-transform: uppercase;
  text-align: initial;
  line-height: 15px;
  letter-spacing: 0.1em;
}

.label-info-input {
  border: 0;
  color: #292929;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  text-align: initial;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.label-info-input.model {
  max-width: 450px;
}

.label-info-input.suggestion {
  max-width: 700px;
}

.input-info-filterSearch {
  margin-top: -20px;
  max-width: 700px;
}

.sidebar {
  width: 342px;
  position: sticky;
  top: 100px;
  z-index: 90;
}

@media (max-width: 1400px) {
  .vehicle {
    padding: 45px 10px 30px 0px;
  }

  .content-input {
    width: -webkit-fill-available;
    padding-left: 10px;
  }
}

@media (max-width: 1024px) {
  .vehicle {
    padding: 45px 20px 30px 0px;
  }
}

@media (max-width: 912px) {

  .sidebar {
    width: auto;
    left: 10px;
    right: 10px;
  }

  .content-field {
    display: block;
    padding-bottom: 30px;
  }

  .content-plate {
    display: grid;
  }

  .content-input {
    width: auto;
    padding-left: 0px;
  }

}

@media (max-width: 767px) {
  .vehicle {
    padding: 25px 5px;
  }
}
</style>
