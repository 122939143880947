<script>
import { mapActions, mapGetters } from 'vuex'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import NumberField from '@/components/NumberField'
import ContentLoading from '@/components/ContentLoading'
import ModalProductImage from '@/components/ModalProductImage'
import ModalUpdateCartItemQuantity from '@/components/ModalUpdateCartItemQuantity'
import ModalConfirmation from '@/components/ModalConfirmation'
import logger from '@/logger/index'

export default {
  name: 'ProductDetail',

  props: { searchNewProduct: Function },

  components: {
    Action,
    Icon,
    NumberField,
    ContentLoading,
    ModalProductImage,
    ModalUpdateCartItemQuantity,
    ModalConfirmation,
  },

  data () {
    return {
      quantity: 1,
      loadingPrice: false,
      loadingProduct: false,
      showImageModal: false,
      showModalQtde: false,
      showModalConfirm: false,
      itens: [],
      updateProduct: null,
    }
  },

  computed: {
    ...mapGetters('products', ['getSelectedProduct', 'getPriceAdd']),
    ...mapGetters('orders', ['getSelectedOrder']),
    ...mapGetters('userPermissions', ['getEnableParts', 'getFavoriteCompany', 'getBlockSale']),

    percentualProductPrice () {
      return 1 + (this?.getPriceAdd / 100)
    },

    priceRule () {
      return this.getSelectedProduct?.price.find(price => this.quantity >= price.min_limit && this.quantity <= price.max_limit) || {}
    },

    subtotal () {
      return (this.quantity * this.priceRule.price) * this.percentualProductPrice
    },

    stockFavoriteCompany () {
      return this.getSelectedProduct?.stock.find(stock => stock.company_id === this.getFavoriteCompany)
    },

    totalStockQuantity () {
      return this.getSelectedProduct?.stock.reduce(
        (total, stock) => total + stock.quantity,
        0
      )
    },
  },

  mounted () {
    this.quantity = this.getSelectedProduct.fraction
  },

  methods: {
    ...mapActions(['setHeaderBack', 'setFeedback']),
    ...mapActions('products', ['getProduct', 'checkProductPrice']),
    ...mapActions('orders', ['addProduct', 'updateProductQuantity']),

    getHumanizedPriceRule (priceRule) {
      if ((priceRule.min_limit === 1) && (priceRule.max_limit === 999999)) {
        return `Para qualquer quantidade`
      }
      if (priceRule.min_limit === 1) {
        return `Até ${priceRule.max_limit} unidades`
      }
      if (priceRule.max_limit === 999999) {
        return `A partir de ${priceRule.min_limit} unidades`
      }
      return `De ${priceRule.min_limit} à ${priceRule.max_limit} unidades`
    },

    checkPrice () {
      this.loadingPrice = true
      this.checkProductPrice({ id: this.getSelectedProduct.id, add: 1 }).then(() => {
        this.loadingPrice = false
      })
    },

    async openProduct (search) {
      this.loading = true
      await this.getProduct({
        search,
      }).then(() => {
        this.loading = false
      })
    },

    async handleAddProduct () {
      logger.debug('handleAddProduct')
      this.loadingProduct = true
      if (this.priceRule.price > 0) {
        logger.debug('Verificando se já possui o produto no carrinho..')
        if (!this.getSelectedOrder.products.find(product => product.id === this.getSelectedProduct.id)) {
          logger.debug('Não possui o produto no carrinho..')
          await this.checkBlockSale({
            list_type: this.priceRule.list_type,
            price: this.priceRule.price,
            quantity: this.quantity,
            product: this.getSelectedProduct,
          })
        } else {
          logger.debug('Abrindo modal para atualizar Qtde..')
          this.openModalQtde()
          this.loadingProduct = false
        }
      } else {
        this.setFeedback({ title: 'Desculpe, produto indisponível no momento', message: '', type: 'error' })
        this.loadingProduct = false
      }
    },

    async checkBlockSale (product) {
      try {
        logger.debug('Iniciando a verificação de estoque do produto: ', product)
        this.updateProduct = product
        if (this.updateProduct && this.updateProduct.quantity !== this.quantity) {
          this.quantity = this.updateProduct.quantity
        }

        if (this.stockFavoriteCompany && this.quantity > this.stockFavoriteCompany.quantity) {
          logger.debug('quantidade requisitada maior que estoque preferencial')
          logger.debug('blockSale: ', this.getBlockSale) // NOTE: getBlockSale é tipo de restrição de estoque do usuário
          await this.handleBlockSale(product)
          this.loadingProduct = false
        } else {
          logger.debug('A qtde possui no estoque, irá inserir agora..')
          await this.addOrUpdateProduct(product)
        }
      } catch (error) {
        logger.error('Erro ao verificar o bloqueio de venda:', error)
      }
    },

    handleBlockSale (product) {
      switch (this.getBlockSale) {
        case 0:
          return this.addOrUpdateProduct(product)
        case 1:
          logger.debug('É maior que todo estoque: ', this.quantity > this.totalStockQuantity)
          if ((this.totalStockQuantity && this.quantity > this.totalStockQuantity) || this.totalStockQuantity === 0) {
            return this.setFeedback({ title: 'Não possui estoque disponível', message: 'Desculpe, mas não há mais unidades deste produto em estoque em todo Estado.', type: 'error' })
          }
          if (product.insertionQueue) {
            logger.debug('Inserindo em fila..')
            return this.addOrUpdateProduct(product)
          }

          return this.openModalConfirmation()
        case 2:
          return this.setFeedback({ title: 'Não possui estoque disponível', message: 'Desculpe, mas não há mais unidades deste produto em estoque na filial preferencial.', type: 'error' })
        default:
          return []
      }
    },

    async addOrUpdateProduct (product, modalStatus) {
      logger.debug('Produto irá ser inserido ou atualizado..')
      logger.debug('addOrUpdateProduct: ', product)
      this.loadingProduct = true
      logger.debug('Verificando se já possui o produto no carrinho antes de inserir..')

      if (!this.getSelectedOrder.products.find(item => item.id === product.product.id)) { // NOTE: Se não existir o item no carrinho
        logger.debug('Será inserido o produto..')
        await this.addProductToOrder(product, modalStatus)
      } else if (this.getSelectedOrder.products.find(item => item.id === product.product.id).quantity !== product.quantity) {
        logger.debug('Será atualizado  o produto..')
        await this.updateQtdeProductToOrder(product.product.id, product.quantity, modalStatus)
      } else {
        logger.debug('Produto já existe no carrinho e possui o mesma quantidade')
        this.finallyOperationProduct(modalStatus)
      }
    },

    async addProductToOrder (product, modalStatus) {
      logger.debug('addProductToOrder: ', product)

      await this.addProduct({
        list_type: product.list_type,
        price: product.price,
        quantity: product.quantity,
        product: product.product,
      }).then(() => {
        if (!product.insertionQueue) { this.setFeedback({ title: 'Produto adicionado com sucesso', message: '', type: 'success' }) }
        this.finallyOperationProduct(modalStatus)
      }).catch((error) => {
        logger.error('Erro ao realizar a operação com o produto insert:', error)
      })
    },

    async updateQtdeProductToOrder (id, quantity, modalStatus) {
      logger.debug('updateQtdeProduct: ', id, quantity)
      await this.updateProductQuantity({
        id: id,
        quantity: quantity,
      }).then(() => {
        this.setFeedback({ title: 'Produto atualizado com sucesso', message: '', type: 'success' })
        this.finallyOperationProduct(modalStatus)
      }).catch((error) => {
        logger.error('Erro ao realizar a operação com o produto update:', error)
      })
    },

    finallyOperationProduct (modalStatus) {
      this.loadingProduct = false
      this.updateProduct = null
      if (modalStatus === 'confirm') {
        this.$refs.modalConfirmation.loading = false
        this.$refs.modalConfirmation.closeModal()
      }
    },

    openImageModal () {
      this.showImageModal = true
    },

    openModalQtde () {
      this.showModalQtde = true
    },

    openModalConfirmation () {
      this.showModalConfirm = true
    },

    handleOpenModal () {
      this.$emit('open')
    },

  },
}
</script>

<template>
  <div :class="$classes['product-detail']">
    <div :class="$classes['product-info']">
      <div :class="$classes['product-image-wrapper']">
        <icon v-if="!getSelectedProduct.image" name="image" :class="$classes['product-image-icon']" />
        <img v-else :src="getSelectedProduct.image" :class="$classes['product-image']" />
        <action variant="flat" color="primary" uppercase :class="$classes['product-image-button']"
          @click="openImageModal">
          <icon name="search-plus" btn-icon />
          Ampliar
        </action>
      </div>
      <div :class="$classes['product-info-content']">
        <div :class="$classes['product-info-columns']">
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label-bold']">Código</span>
            <span :class="$classes['product-info-value-bold']">{{ getSelectedProduct.code }}</span>
          </div>
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label']">Fábrica</span>
            <span :class="$classes['product-info-value']">{{ getSelectedProduct.factory }}</span>
          </div>
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label']">NCM</span>
            <span :class="$classes['product-info-value']">{{ getSelectedProduct.ncm }}</span>
          </div>
        </div>
        <div :class="$classes['product-info-columns']">
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label']">Descrição</span>
            <span :class="$classes['product-info-value']">{{ getSelectedProduct.description }}</span>
          </div>
        </div>
        <div :class="$classes['product-info-columns']">
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label']">Fração Venda</span>
            <span :class="$classes['product-info-value']">{{ getSelectedProduct.fraction }}</span>
          </div>
          <div :class="$classes['product-info-column']">
            <span :class="$classes['product-info-label']">Grupo Lista</span>
            <span :class="$classes['product-info-value']">{{ getSelectedProduct.group_list }}</span>
          </div>
        </div>
        <div :class="$classes['product-info-column']">
          <action :class="$classes['product-check-price']" variant="flat" color="primary" uppercase bold
            :disabled="loadingPrice" @click="checkPrice">
            <icon name="search-dollar" btn-icon />
            Consultar preço
          </action>
          <action v-if="getEnableParts" :class="$classes['product-check-price']" variant="flat" color="primary" uppercase
            bold @click="handleOpenModal">
            <icon name="wrench" btn-icon />
            Conjunto de Peças
          </action>
        </div>
      </div>
    </div>
    <div :class="$classes['product-price']">
      <content-loading v-if="loadingPrice" :class="$classes['product-price-content-loading']" />
      <template v-if="getSelectedProduct.price != null && !loadingPrice">
        <div v-if="getSelectedProduct.product_ipi" :class="$classes['product-price-header']">
          <span :class="{ [$classes['product-price-label']]: true, [$classes['red']]: true }">
            Produto suscetível a cobrança de IPI
          </span>
        </div>
        <div :class="$classes['product-price-header']">
          <span :class="$classes['product-price-label']">Qtd.:</span>
          <number-field light v-model="quantity" :min="getSelectedProduct.fraction" :step="getSelectedProduct.fraction" />
          <span :class="$classes['product-price-subtotal']">Subtotal: <strong>{{ subtotal | currency
          }}</strong></span>
          <action v-if="$media.mobile" variant="contained" color="primary" :class="$classes['product-price-btn']"
            :disabled="loadingProduct || quantity < getSelectedProduct.fraction" :loading="loadingProduct"
            @click="handleAddProduct">
            <icon name="plus" />
          </action>
          <action v-else variant="contained" color="primary" :class="$classes['product-price-btn']"
            :disabled="loadingProduct || quantity < getSelectedProduct.fraction" :loading="loadingProduct"
            @click="handleAddProduct">
            <icon name="plus" btnIcon />
            Adicionar
          </action>
        </div>
        <div :class="$classes['product-price-content']">
          <div :class="$classes['product-price-rules']">
            <template v-for="(priceRule, index) in getSelectedProduct.price">
              <div :key="index" :class="$classes['product-price-rule-item']">
                <span :class="$classes['product-price-rule-label']">Opção {{ index + 1 }}</span>
                <span :class="$classes['product-price-rule-quantity']">{{
                  getHumanizedPriceRule(priceRule)
                }}</span>
                <span :class="$classes['product-price-rule-value']">{{
                  priceRule.price * percentualProductPrice | currency
                }}</span>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div :class="$classes['product-highlight-columns']">
      <div :class="$classes['product-highlight-info']">
        <content-loading :class="$classes['product-highlight-columns-loading']"
          v-if="getSelectedProduct.stock.length === 0" />
        <table v-if="getSelectedProduct.stock.length > 0">
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Em estoque</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(stock, index) in getSelectedProduct.stock">
              <tr :key="index">
                <td>{{ stock.company }}</td>
                <td>{{ stock.quantity }}</td>
              </tr>
            </template>
            <tr>
              <td><strong>Total:</strong></td>
              <td>{{ totalStockQuantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :class="$classes['product-highlight-info']">
        <table style="">
          <thead>
            <tr>
              <th>Produto Equivalente</th>
              <th>Indústria</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(similarProduct, index) in getSelectedProduct.similar">
              <tr :key="index">
                <td>
                  <action :class="$classes['order-cart-products-btn']" @click="openProduct(similarProduct.code)">
                    {{ similarProduct.code }}
                  </action>
                </td>
                <td style="">
                  {{ similarProduct.company }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div :class="$classes['product-highlight-info']">
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Original</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(originalProduct, index) in getSelectedProduct.original">
              <tr :key="index">
                <td>{{ originalProduct.code }}</td>
                <td>{{ originalProduct.original }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <modal-confirmation ref="modalConfirmation" :active.sync="showModalConfirm"
      :stock="stockFavoriteCompany && stockFavoriteCompany.quantity" :product="updateProduct"
      @toOrder="addOrUpdateProduct" />

    <modal-product-image :active.sync="showImageModal" :image="getSelectedProduct.image" />

    <modal-update-cart-item-quantity ref="modalUpdateQtde" :active.sync="showModalQtde" :product="getSelectedProduct"
      :currentQuantity="quantity" @updateQuantity="checkBlockSale" />
  </div>
</template>

<style module>
.header-title {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #292929;
  margin-bottom: 20px;
  padding: 0px 30px 0px 0px;
}

.product-check-price {
  margin-top: -15px;
  margin-bottom: 5px;
  margin-right: 20px;
  padding: 10px;
  align-items: flex-start;
}

.product-detail {
  margin-top: 30px;
}

.product-info {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.product-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 2px;
  width: 30%;
  height: 250px;
  margin-right: 20px;
  overflow: hidden;
  color: #A1A1A1;
  position: relative;
  max-width: 160px;
  min-width: 160px;
}

.product-image-wrapper::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.75%, #FFFFFF 100%);
  z-index: 2;
}

.product-image-icon {
  width: 16px;
  height: 16px;
}

.product-image {
  display: block;
  width: 100%;
  height: auto;
}

.product-image-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
}

.product-info-columns {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.product-info-column+.product-info-column {
  margin-left: 25px;
}

.product-info-label {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #a1a1a1;
  margin-bottom: 10px;
}

.product-info-label-bold {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #a1a1a1;
  margin-bottom: 10px;
}

.product-info-value {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #292929;
  text-transform: uppercase;
}

.product-info-value-bold {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #292929;
  text-transform: uppercase;
}

.product-price {
  margin-bottom: 10px;
}

.product-price-header {
  background: #E7EAEC;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 2px;
}

.product-price-label {
  font-size: 12px;
  color: #292929;
  text-transform: uppercase;
  margin-right: 10px;
}

.product-price-label.red {
  color: red;
  font-weight: 500;
}

.product-price-subtotal {
  font-size: 12px;
  color: #292929;
  text-transform: uppercase;
  margin-left: 15px;
  flex: 1;
}

.product-price-btn {
  height: 40px;
  padding: 0 15px;
}

.product-addOrder-btn {
  height: 40px;
  padding: 0 15px;
  float: right;
}

.product-price-content {
  border-radius: 2px;
  background: #fff;
  padding: 20px;
}

.product-price .product-price-content-loading {
  height: 135px;
}

.product-price-rule-item {
  display: flex;
}

.product-price-rule-item+.product-price-rule-item {
  margin-top: 15px;
}

.product-price-rule-label {
  font-size: 12px;
  color: #a1a1a1;
  text-transform: uppercase;
  width: 130px;
}

.product-price-rule-quantity {
  font-size: 12px;
  color: #292929;
  text-transform: uppercase;
  flex: 1;
}

.product-price-rule-value {
  width: 90px;
  font-size: 12px;
  color: #292929;
}

.product-highlight-info {
  background: #E7EAEC;
  padding: 20px;
  border-radius: 2px;
}

.product-highlight-columns {
  display: flex;
  margin-top: 10px;
}

.product-highlight-info .product-highlight-columns-loading {
  height: 100%;
}

.product-highlight-columns .product-highlight-info {
  flex: 1;
  width: 50%;
}

.product-highlight-columns .product-highlight-info+.product-highlight-info {
  margin-left: 10px;
}

.product-highlight-info-item {
  display: flex;
  align-items: center;
}

.product-highlight-info-item+.product-highlight-info-item {
  margin-top: 15px;
}

.product-highlight-label {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #a1a1a1;
  width: 130px;
  margin-right: 5px;
}

.product-highlight-value {
  flex: 1;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #292929;
}

.product-highlight-info table {
  width: 100%;
}

.product-highlight-info th {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1a1a1;
  width: 50%;
  text-align: left;
  height: 30px;
}

.product-highlight-info td {
  font-size: 12px;
  color: #292929;
  text-align: left;
  height: 30px;
}

.order-cart-products-btn {
  text-align: left;
  display: inline-block;
  font-size: 14px;
  color: #4A94D4;
  text-decoration: underline;
  line-height: 20px;
}

@media (max-width: 800px) {
  .product-highlight-columns {
    flex-direction: column;
  }

  .product-highlight-columns .product-highlight-info {
    width: 100%;
  }

  .product-highlight-columns .product-highlight-info+.product-highlight-info {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .product-info {
    flex-direction: column;
  }

  .product-check-price {
    margin-top: -15px;
    margin-bottom: 5px;
    margin-right: 20px;
    padding: 20px;
    align-items: flex-start;
  }

  .product-info-column {
    display: flex;
    flex-direction: column;
  }

  .product-image-wrapper {
    margin-bottom: 20px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    max-width: -webkit-fill-available;
    height: 100vw;
  }

}
</style>
